<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/usermanagement/usermanagementlist')" class="el-icon-close"></i>
      </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline topm">
      
            <el-form-item :required="true" label="昵称:">
                 <el-input  v-model="formInline.nickname"></el-input>
            </el-form-item><br>
            <el-form-item :required="true" label="头像:">
              <div class="block"  v-for="(img,index) in list" :key="img.id">
               <i @click.stop="deleimage(index)" class="el-icon-close imgii"></i>
                    <el-image
                    style="width: 150px; height: 150px"
                    :src="img.src"
                    fit="fill">
                    </el-image>
              </div>
               <input ref="filElem" type="file" name='file[]' hidden class="upload-file" @change="getFile" multiple>
            <div v-if="list.length == 0"  @click="choiceImg" class="el-upload el-upload--picture-card">
                <i  style="margin-top: 55px;margin-left: 10px;" class="el-icon-plus"></i>
            </div>
            </el-form-item><br>
            <el-form-item :required="true" label="个人简介:">
                <el-input
                type="textarea"
                    maxlength="1000"
                    show-word-limit
                    style="width:800px;"
                     :autosize="{ minRows: 4, maxRows: 8}"
                placeholder="请输入内容"
                v-model="formInline.description">
                </el-input>
            </el-form-item>
        <div class="pt20"></div>
            <el-form-item>
                <el-button type="primary" style="margin-left:300px" @click="onSubmit()">保存</el-button>
            </el-form-item>
        </el-form>

      <div class="pt20"></div>

    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      formInline: {
          user_id:'',
          nickname:'',
          headimg:'',
          description:'',
      },
      list:[],
      options:[],   
      timevalue:'',
      loading: false,
      userSearch:[],
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      onSubmit(){
        let _this = this;
        if(_this.formInline.nickname === ''  || _this.formInline.description === '' || _this.list.length == 0 ){
                _this.$message.error('请填写必填项');return;
        }
        if(_this.list[0].file){
        let formdata = new FormData();
        formdata.append("file", _this.list[0].file);
         axios.post(config.uploadHeadimg,formdata,{headers:{'Content-Type':'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.formInline.headimg = response.data.data;
                        axios.post(config.consumerEdit,_this.formInline)
                          .then(function (response) {
                              if(response.data.code == 200){
                                _this.$message({
                                  message: response.data.message,
                                  type: 'success'
                                });
                                _this.goNav('/usermanagement/usermanagementlist');
                              }else{
                                  _this.$message.error(response.data.message);
                              }
                            })
                          .catch(function (error) {
                            console.log(error);
                          });
                }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        }else{
              _this.formInline.headimg = _this.list[0].src;
            axios.post(config.consumerEdit,_this.formInline)
                          .then(function (response) {
                              if(response.data.code == 200){
                                _this.$message({
                                  message: response.data.message,
                                  type: 'success'
                                });
                                _this.goNav('/usermanagement/usermanagementlist');
                              }else{
                                  _this.$message.error(response.data.message);
                              }
                            })
                          .catch(function (error) {
                            console.log(error);
                          });
        }
      },
    deleimage(index){
      this.list.splice(index, 1);
    },
    choiceImg(){
        if(this.list.length>1){
            return;
        }
         this.$refs.filElem.dispatchEvent(new MouseEvent('click')) 
    },
    getFile(){
            var that = this;
            let files = this.$refs.filElem.files;
            for(let i in files){
              if(i == 'length'){
                return;
              }else{
                if(this.list.length+i*1+1> 6){
                  return
                }
              }
              let inputFile = files[i];
           // console.log(inputFile)
          //  const inputFile = this.$refs.filElem.files[0];
            if(inputFile){
                if(inputFile.type !== 'image/jpeg' && inputFile.type !== 'image/png' && inputFile.type !== 'image/gif'){
                  console.log('上传文件中有格式不对');
                    return;
                }
                this.imgInfo = Object.assign({}, this.imgInfo, {
                    name: inputFile.name,
                    size: inputFile.size,
                    lastModifiedDate: inputFile.lastModifiedDate.toLocaleString()
                })
                const reader = new FileReader();
                reader.readAsDataURL(inputFile);
                reader.onload = function () {
                    let id = new Date().getTime();
                    let imgSrc = this.result;
                    that.list.push({
                        id:id,
                        src:imgSrc,
                        file:inputFile,
                    });
                }
            } else {
                return;
            }
            }
        },
      goNav(url){
        this.$router.push(url)
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        axios.get(config.consumerInfo,{params:{id:obj.keyid}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.formInline.user_id = response.data.data.keyid;
                _this.formInline.nickname = response.data.data.nickname;
                _this.formInline.description = response.data.data.description;
                _this.list.push({
                    src:response.data.data.headimg
                });
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
.el-icon-close:before{
  font-size: 24px;
}
.topm{
  margin-top: 20px;
}
.imgii{
  position: absolute;
  right: 0;
  z-index: 999;
  font-size: 24px;
}
.block{
  position: relative;
}
</style>